.work {
    .cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
        height: 80vh;
        overflow: scroll;
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;
    }
}
