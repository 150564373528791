@import '../../Styles/Index.scss';

.home {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 75vh;
  .info-section {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    flex-basis: 34rem;

    h1 {
      font-size: 4rem;
      color: white;
    }

    h3 {
      font-weight: 400;
      font-size: 1.3rem;
    }

    p {
      font-size: 16px;
    }
  }

  .image-section {
    border: 2px dashed #ADFF2F;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 700px){
  .home{
    flex-direction: column;
    height: 110vh;
    overflow: hidden;
    .info-section{
      padding: 2rem;
      h1{
        font-size: 35px;
      }
    }
    .image-section{
      width: 85vw;
      order: -1;
    }
  }

}