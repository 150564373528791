@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800;900&display=swap');

$primaryColor : #ADFF2F;
$primaryHeading : 4.1rem;
$buttonText: black;
$backgroundColor: #1b1f22;

* {
    margin: 0;
    padding: 0;
}


html::-webkit-scrollbar {
    display: none;
}

html {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #1b1f22;
    color: white;
}

a {
    text-decoration: none;
    color: white;
}

.container {
    padding: 14px;
    max-width: 1180px;
    margin: auto;
    justify-content: center;
}
